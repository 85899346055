import { Controller } from '@hotwired/stimulus'
import { environment, promiseTimeout } from 'scripts/utils/document'

export default class extends Controller {
  static targets = ['block', 'fallback', 'template']
  static values = {
    group: String,
    type: String
  }

  connect() {
    if (environment === 'development') {
      if (this.typeValue === 'block') {
        this.displayBlock()
      }
    } else {
      this.getOneTrust()
    }
  }

  async getOneTrust() {
    if (!window.OneTrust || !window.OptanonActiveGroups) {
      await promiseTimeout(this.waitForOneTrust(), 15000)
    }

    this.previousActiveGroups = window.OptanonActiveGroups
    this.checkConsent()

    window.OneTrust.OnConsentChanged(() => {
      if (!window.OptanonActiveGroups.includes('C0003') && this.previousActiveGroups.includes('C0003')) {
        window.location.reload()
      } else {
        this.checkConsent()
      }

      this.previousActiveGroups = window.OptanonActiveGroups
    })
  }

  waitForOneTrust() {
    return new Promise((resolve, reject) => {
      if (window.OneTrust && window.OptanonActiveGroups) {
        resolve()
      } else {
        setTimeout(() => {
          resolve(this.waitForOneTrust())
        }, 1000)
      }
    })
  }

  checkConsent() {
    if (this.typeValue === 'block') {
      if (window.OptanonActiveGroups.indexOf(this.groupValue) > -1) {
        this.displayBlock()
      } else {
        this.displayFallback()
      }
    }
  }

  displayBlock() {
    if (this.hasTemplateTarget && !this.hasBlockTarget) {
      this.fallbackTarget.classList.add('u-d-none')
      this.element.appendChild(this.templateTarget.content.cloneNode(true).firstElementChild)
    }
  }

  displayFallback() {
    if (this.hasBlockTarget) {
      this.blockTarget.remove()
    }

    this.fallbackTarget.classList.remove('u-d-none')
  }
}
