// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'stylesheets/lagoon/style'
import 'scripts/application'

import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const applicationContext = require.context('scripts/application/controllers', true, /\.js$/)
const lagoonContext = require.context('scripts/lagoon/controllers', true, /\.js$/)

Stimulus.load([
  ...definitionsFromContext(applicationContext),
  ...definitionsFromContext(lagoonContext)
])

